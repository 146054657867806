import React, { Component } from 'react';

export class Loadings extends Component {
  render() {
    const { classes } = this.props;
    const size = this.props.size ? this.props.size : 40;
    return(
      <div className="MuiCircularProgress-root WithStyles(ForwardRef(CircularProgress))-root-1 MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{height: size,width: size}}>
        <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
            <circle className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate" cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
        </svg>
      </div>
    )
  }
}


export class BarLoadings extends Component {
  render() {
    const { classes } = this.props;
    return(
      <div className="MuiLinearProgress-root WithStyles(ForwardRef(LinearProgress))-root-1 MuiLinearProgress-colorPrimary MuiLinearProgress-indeterminate" role="progressbar"><div className="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Indeterminate"></div><div className="MuiLinearProgress-bar MuiLinearProgress-bar2Indeterminate MuiLinearProgress-barColorPrimary"></div></div>
    )
  }
}
