import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDSPD12D6sSmK3yxPMwXFu9dbtogKiEYBI",
    authDomain: "invoicepat.firebaseapp.com",
    databaseURL: "https://invoicepat.firebaseio.com",
    projectId: "invoicepat",
    storageBucket: "invoicepat.appspot.com",
    messagingSenderId: "373919153889",
    appId: "1:373919153889:web:211dd2e140d08016dc81a0",
    measurementId: "G-2XJ8CQF2BV"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
//const baseDb = myFirebase.firestore();
//export const db = baseDb;

export const storage = myFirebase.storage();
export const storageRef = storage.ref();